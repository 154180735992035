<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <v-card-title class="elevation-0">
          <span class="itemHeading text-uppercase"> SHIFT DETAILS</span>
          <v-spacer></v-spacer>
          <v-flex xs6 text-right v-if="position == '62a2de91ecd959516cd3b0e6'">
            <v-btn
              color="#005f32"
              dark
              outlined
              @click="dialogAddShift = true"
              rounded
              >Add</v-btn
            >
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-center pb-5>
          <v-flex xs12 px-5>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead style="background-color: #005f32">
                  <tr>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Shift Name
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Login Time
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Interval 1
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Interval 2
                    </th>
                    <th
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Logout Time
                    </th>
                    <th
                      v-if="position == '62a2de91ecd959516cd3b0e6'"
                      style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      "
                      class="text-left"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody class="align-center">
                  <tr
                    class="text-left align-center"
                    v-for="(item, i) in shift"
                    :key="i"
                  >
                    <td>
                      <span
                        v-if="item.shiftName"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.shiftName }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.loginTime"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.loginTime }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.otherTimes"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.otherTimes[0] }}</span
                      >
                    </td>

                    <td>
                      <span
                        v-if="item.otherTimes"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.otherTimes[1] }}</span
                      >
                    </td>
                    <td>
                      <span
                        v-if="item.logoutTime"
                        class=""
                        style="font-family: kumbhMedium"
                        >{{ item.logoutTime }}</span
                      >
                    </td>
                    <td v-if="position == '62a2de91ecd959516cd3b0e6'">
                      <v-icon @click="editItem(item)" color="green"
                        >mdi-pencil</v-icon
                      >
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-flex>
          <v-flex xs12 pt-5>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-card-title class="elevation-0">
          <span class="itemHeading text-uppercase"> WEEKLY WORKING DAYS</span>
          <v-spacer></v-spacer>
          <v-flex
            xs6
            sm6
            md8
            text-left
            v-if="position == '62a2de91ecd959516cd3b0e6'"
          >
            <v-btn
              color="#005f32"
              dark
              outlined
              @click="dialogUpdateWorking = true"
              rounded
              >Update</v-btn
            >
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-center>
          <v-flex xs12 px-5>
            <v-chip-group>
              <v-chip
                v-for="item in days"
                :key="item"
                :color="weekWorkingDays.includes(item) ? '#005f32' : '#9e9d9d'"
              >
                <span style="font-family: kumbhRegular; color: #ffffff">
                  {{ item }}</span
                >
              </v-chip>
            </v-chip-group>
          </v-flex>
          <v-flex xs12 pt-8>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout wrap pt-5>
          <v-flex xs12 sm5>
            <v-card-title class="elevation-0">
              <span class="itemHeading text-uppercase"> HOLIDAYS</span>
              <v-spacer></v-spacer>
              <v-flex
                xs6
                sm6
                md8
                text-left
                v-if="position == '62a2de91ecd959516cd3b0e6'"
              >
                <v-btn
                  color="#005f32"
                  dark
                  outlined
                  @click="dialogUpdateDays = true"
                  rounded
                  >Update</v-btn
                >
              </v-flex>
            </v-card-title>
            <v-layout wrap justify-start>
              <v-flex xs12 sm12>
                <v-date-picker
                  v-model="dates"
                  multiple
                  full-width
                  readonly
                  no-title
                  color="#005f32"
                  class="mt-4"
                ></v-date-picker>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs1 sm1>
            <v-divider vertical></v-divider>
          </v-flex>
          <v-flex xs11 sm5>
            <v-card-title class="elevation-0">
              <span class="itemHeading text-uppercase"> LEAVES</span>
              <v-spacer></v-spacer>
              <v-flex
              v-if="position == '62a2de91ecd959516cd3b0e6'"
              >
                <v-btn
                  color="#005f32"
                  dark
                  outlined
                  rounded
                  :to="'/Attendance/leaveAddorUpdate'"
                  >Add/Update</v-btn
                >
              </v-flex>

              <v-flex
              v-if="position == '62a2de91ecd959516cd3b0e6'"
              >
                <v-btn
                  color="#005f32"
                  dark
                  outlined
                  rounded
                  :to="'/Attendance/festivalLeaves'"
                  >Festival Leaves</v-btn
                >
              </v-flex>
             
            </v-card-title>
            <v-layout wrap justify-start>
              <v-flex xs12 sm12>
                <apexchart
                  type="pie"
                  height="270px"
                  width="100%"
                  style="font-size: 6px"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-dialog persistent v-model="dialogAddShift" max-width="50%">
          <v-card tile>
            <v-card-title>
              <span class="itemHeading">Please Select Shift Timings</span>
            </v-card-title>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12 sm6 pt-5 px-5 text-left>
                  <span class="itemText2">LOGIN TIME</span>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="login"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="login"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu"
                      v-model="login"
                      full-width
                      @click:minute="$refs.menu.save(login)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 pt-5 px-5 text-left>
                  <span class="itemText2">INTERVAL TIME(1st)</span>
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="interval1"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="interval1"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu1"
                      v-model="interval1"
                      full-width
                      @click:minute="$refs.menu1.save(interval1)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 px-5 text-left>
                  <span class="itemText2">INTERVAL TIME(2nd)</span>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="interval2"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="interval2"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="interval2"
                      full-width
                      @click:minute="$refs.menu2.save(interval2)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 px-5 text-left>
                  <span class="itemText2"> LOGOUT TIME</span>
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="logout"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="logout"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu3"
                      v-model="logout"
                      full-width
                      @click:minute="$refs.menu3.save(logout)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogAddShift = false">
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="addShift()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogEditShift" max-width="50%">
          <v-card tile>
            <v-card-title>
              <span class="itemHeading">Please Select Shift Timings</span>
            </v-card-title>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12 sm6 pt-5 px-5 text-left>
                  <span class="itemText2">LOGIN TIME</span>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="edit.loginTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="edit.loginTime"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu"
                      v-model="edit.loginTime"
                      full-width
                      @click:minute="$refs.menu.save(edit.loginTime)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 pt-5 px-5 text-left>
                  <span class="itemText2">INTERVAL TIME(1st)</span>
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="edit.interval1"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="edit.interval1"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu1"
                      v-model="edit.interval1"
                      full-width
                      @click:minute="$refs.menu1.save(edit.interval1)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 px-5 text-left>
                  <span class="itemText2">INTERVAL TIME(2nd)</span>
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="edit.interval2"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="edit.interval2"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu2"
                      v-model="edit.interval2"
                      full-width
                      @click:minute="$refs.menu2.save(edit.interval2)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm6 px-5 text-left>
                  <span class="itemText2"> LOGOUT TIME</span>
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="edit.logoutTime"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="edit.logoutTime"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu3"
                      v-model="edit.logoutTime"
                      full-width
                      @click:minute="$refs.menu3.save(edit.logoutTime)"
                    ></v-time-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogEditShift = false"
              >
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="editShift()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogUpdateWorking" max-width="50%">
          <v-card tile>
            <v-card-title>
              <span class="itemHeading">WORKING DAYS</span>
            </v-card-title>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12 sm12 pt-5 px-5 text-left>
                  <v-combobox
                    v-model="weekWorkingDays"
                    :items="days"
                    label="Combobox"
                    multiple
                    outlined
                    dense
                  ></v-combobox>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogUpdateWorking = false"
              >
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="update()"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="dialogUpdateDays" max-width="50%">
          <v-card tile>
            <v-card-title>
              <span class="itemHeading">Choose the Holiday Dates</span>
            </v-card-title>
            <v-card-text>
              <v-layout wrap>
                <v-flex xs12 sm12 pt-5 px-5 text-left>
                  <v-menu
                    ref="menu4"
                    v-model="menu4"
                    :close-on-content-click="false"
                    :return-value.sync="dates"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox
                        v-model="dates"
                        multiple
                        chips
                        outlined
                        small-chips
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-combobox>
                    </template>
                    <v-date-picker v-model="dates" multiple no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu4 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu4.save(dates)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogUpdateDays = false"
              >
                Close
              </v-btn>
              <v-btn color="blue darken-1" text @click="updateDate()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
    
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  components: {
    VueElementLoading,
  },
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      appLoading: false,
      data: [],
      shift: [],
      login: "",
      menu: false,
      interval1: "",
      menu1: false,
      interval2: "",
      menu2: false,
      logout: "",
      menu3: false,
      menu4: false,
      holidays: [],
      dates: [],
      selectedarr: [],
      weekWorkingDays: [],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      series: [],
      chartOptions: {
        chart: {
          type: "pie",
        },
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      leavesCount: [],
      dialogAddShift: false,
      dialogEditShift: false,
      edit: "",
      dialogUpdateWorking: false,
      dialogUpdateDays: false,
    };
  },
  computed: {
    position() {
      return localStorage.getItem("positionId");
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/organisation/profile/view",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.data;
          this.shift = this.data.shift;
          this.holidays = this.data.holidays;
          for (var i = 0; i < this.holidays.length; i++) {
            this.dates[i] = this.holidays[i].slice(0, 10);
          }
          this.weekWorkingDays = this.data.weekWorkingDays;
          this.leavesCount = response.data.data.leaveDetails;
          this.series=[];
          for (var l = 0; l < this.leavesCount.length; l++) {
            var ty = l + 1;
            this.leavesCount[l].index = ty;
            this.series.push(this.leavesCount[l].count);
            this.chartOptions.labels.push(this.leavesCount[l].leaveCategory);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    addShift() {
      var data1 = {};
      var ot = [];
      var shift = [];
      ot[0] = this.interval1;
      ot[1] = this.interval2;
      shift.push({
        loginTime: this.login,
        logoutTime: this.logout,
        otherTimes: ot,
      });
      data1["shift"] = shift;
      axios({
        method: "POST",
        url: "/organisation/shift/add",
        data: data1,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.visible = false;
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.dialogAddShift = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.visible = false;
          if (err.response.data.status === false) {
            this.msg = "Please Try Again";
          }
        });
    },
    editItem(item) {
      this.edit = JSON.parse(JSON.stringify(item));
      this.edit.interval1 = item.otherTimes[0];
      this.edit.interval2 = item.otherTimes[1];
      this.dialogEditShift = true;
    },
    editShift() {
      var shift = [];
      shift.push({
        loginTime: this.edit.loginTime,
        logoutTime: this.edit.logoutTime,
        otherTimes: [this.edit.interval1, this.edit.interval2],
      });
      axios({
        method: "POST",
        url: "/organisation/shift/edit/" + this.edit._id,
        data: {
          shift: shift[0],
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.visible = false;
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.dialogEditShift = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.visible = false;
          if (err.response.data.status === false) {
            this.msg = "Please Try Again";
          }
        });
    },
    update() {
      var data1 = {};
      data1["weekWorkingDays"] = this.weekWorkingDays;
      axios({
        method: "POST",
        url: "/organisation/weekWorkingDays/edit",
        data: data1,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.visible = false;
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.dialogUpdateWorking = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.visible = false;
          if (err.response.data.status === false) {
            this.msg = "Please Try Again";
          }
        });
    },
    updateDate() {
      var data1 = {};
      data1["holidays"] = this.dates;
      axios({
        method: "POST",
        url: "/organisation/holidays/edit",
        data: data1,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.visible = false;
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.dialogUpdateDays = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.visible = false;
          if (err.response.data.status === false) {
            this.msg = "Please Try Again";
          }
        });
    },
  },
};
</script>
<style scoped>
.modal-footer {
  display: none !important;
}
td {
  font-weight: bold;
}
</style>
    